import axios from 'axios';
import * as qs from 'qs';
import { Paginated, PaginationQuery } from '../../Pagination';
import { Entity, EntityStatus } from './Entity';
import { ADMIN_API_HOST } from '../../Config';
import { addUnauthorizedInterceptor } from '../../ApiUtils';

export class NiobiAdminEntityApi {
  private readonly api;

  constructor(
    private readonly accessToken: string | undefined,
    private readonly onUnauthorized: VoidFunction,
  ) {
    this.api = axios.create({
      baseURL: `${ADMIN_API_HOST}/entity/`,
      headers: { Authorization: `Bearer ${accessToken}` },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });
    addUnauthorizedInterceptor(this.api, onUnauthorized);
  }

  async findOne(id: number): Promise<Entity> {
    return this.api.get(`/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query entity', err.response?.data);
        throw new Error('Unable to fetch entity at the moment. Please try again later.');
      });
  }

  async findAll(query?: EntityQueryDto): Promise<Paginated<Entity>> {
    return this.api.get('/', {
      params: query,
    })
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query entities', err.response?.data);
        throw new Error('Unable to fetch entities at the moment. Please try again later.');
      });
  }

  async getUserCountries(): Promise<any> {
    return this.api.get('/countries/4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6')
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query entities', err.response?.data);
        throw new Error('Unable to fetch entities at the moment. Please try again later.');
      });
  }

  async update(id: number, update: EntityUpdateDto): Promise<Entity> {
    console.log('Entity to be updated', update);
    return this.api.patch(`/${id}`, update)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to update entity', err.response?.data);
        throw new Error('Unable to update entity at the moment. Please try again later.');
      });
  }

  async uploadDocuments(entityId: string): Promise<void> {
    return this.api.post('/documents', { entityId })
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to upload documents', err.response?.data);
        throw new Error('Unable to upload documents at the moment. Please try again later.');
      });
  }

  async verifyAPIClient(userId:string): Promise<void> {
    return this.api.post('/verify-api-business', { userId, forward: true, step: 8 })
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to upload documents', err.response?.data);
        throw new Error('Unable to upload documents at the moment. Please try again later.');
      });
  }

  async verifyAPIClientOnStaging(entityName:string, status:string): Promise<void> {
    return this.api.post('/verify-api-business-on-staging', { entityName, status })
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to upload documents', err.response?.data);
        throw new Error('Unable to upload documents at the moment. Please try again later.');
      });
  }

  async verifyEntityDetails(entityId:number): Promise<void> {
    return this.api.get(`/verify-entity-details/${entityId}`)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to verify entity', err.response?.data);
        throw new Error('Unable to verify entity at the moment. Please try again later.');
      });
  }

  async submitEntityToChoice(entityId: string, actionId:string): Promise<void> {
    return this.api.post('/submit', { entityId, actionId })
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to submit entity to choice', err.response?.data);
        throw new Error('Unable to submit entity to choice at the moment. Please try again later.');
      });
  }

  async getStats(): Promise<void> {
    return this.api.get('/stats')
      .then((response) => response.data)
      .catch((err) => {
        console.error('Unable to get stats', err.response?.data);
        throw new Error('Unable to get analytucs at the moment. Please try again later.');
      });
  }

  async getOnboardingInfo(id: string): Promise<any> {
    return this.api.get(`/onboarding-info/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to update entity', err.response?.data);
        throw new Error('Unable to get onboarding information at the moment. Please try again later.');
      });
  }

  async applyFcyAccount(id: string): Promise<any> {
    return this.api.get(`/apply-fcy-account/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to update entity', err.response?.data);
        throw new Error('Unable to get onboarding information at the moment. Please try again later.');
      });
  }

  async getProfiles(): Promise<any> {
    return this.api.get('/profiles')
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to get profiles', err.response?.data);
        throw new Error('Unable to get profiles at the moment. Please try again later.');
      });
  }
}

export type EntityQueryDto = {
  ids?: number[];
  nameLike?: string;
  registrationNumber?: string;
  entityTypeIds?: number[];
  entityActivityIds?: number[];
  moniteId?: string;
  type?: string;
  legalNameLike?: string;
  vatId?: string;
  legalEntityId?: string;
  taxIdentificationNumber?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  createdBefore?: Date;
  createdAfter?: Date;
  updatedBefore?: Date;
  updatedAfter?: Date;
  status?: EntityStatus;
  isBankCustomer?: boolean;
  // user
  userFirstNameLike?: string;
  userLastNameLike?: string;
  userNameLike?: string;
  userEmail?: string;
} & PaginationQuery;

export type EntityUpdateDto = Omit<Partial<Entity>, 'id'>;
